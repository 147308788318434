@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap");

.react-datepicker-wrapper {
  display: none !important;
}

.react-datepicker__tab-loop {
  width: 100% !important;
}

.main-page-search-bar .react-datepicker__tab-loop {
  width: 70% !important;
}

.react-datepicker-popper {
  width: 100% !important;
  left: 0% !important;
  position: static !important;
  transform: none !important;
}

.main-page-search-bar .react-datepicker-popper {
  width: 70% !important;
  left: 30% !important;
}

.react-datepicker {
  font-family: "Lexend Deca", sans-serif !important;
  width: 100% !important;
  position: absolute !important;
  z-index: 49 !important;
  left: 0% !important;
  display: flex !important;
  top: 100% !important;
  margin-top: 8px;
  background-color: #fbf7f2 !important;
  row-gap: 10rem !important;
}

.booking-summary .react-datepicker {
  left: -2.5% !important;
  width: 105% !important;
}

.main-page-search-bar .react-datepicker {
  left: 30% !important;
  width: 70% !important;
}

.single-page-header .react-datepicker__month-container,
.booking-summary .react-datepicker__month-container {
  width: 100% !important;
  padding: 0px !important;
}

.react-datepicker__month-container {
  width: 45% !important;
  padding: 10px !important;
  box-sizing: border-box !important;
}

.main-page-search-bar .react-datepicker__month-container {
  width: 50% !important;
  padding: 0px !important;
}

.react-datepicker__month {
  text-align: right !important;
  margin: 0 !important;
  justify-content: space-between !important;
}

.main-page-search-bar .react-datepicker__month {
  justify-content: flex-start !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
}

.react-datepicker {
  box-shadow: none;
  border: 2px solid #f3e6d8 !important;
  font-family: "Lexend Deca", sans-serif !important;
  background-color: #fdf4e3;
  padding: 20px !important;
}

.main-page-search-bar .react-datepicker {
  padding: 20px 15px !important;
  padding-right: 2.5px !important;
}

.react-datepicker__header {
  width: 100% !important;
  border-bottom: none !important;
  background-color: #fbf7f2 !important;
  background: #fbf7f2 !important;
  font-family: "Lexend Deca", sans-serif !important;
  color: transparent !important;
  font-weight: 500 !important;
  padding: 0px !important;
}

.react-datepicker__navigation--previous {
  background-image: url("https://res.cloudinary.com/dz3tveb47/image/upload/v1741179102/Back_htmhtp.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  left: 20px !important;
}

.react-datepicker__navigation--next {
  background-image: url("https://res.cloudinary.com/dz3tveb47/image/upload/v1741179102/Back_htmhtp.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  transform: rotate(180deg);
  right: 20px !important;
}

.react-datepicker__navigation--previous > span::before,
.react-datepicker__navigation--next > span::before {
  display: none;
}

.react-datepicker__day-names,
.react-datepicker__week {
  width: 95%;
  display: flex !important;
  justify-content: space-between !important;
}

.react-datepicker__day-names {
  margin: 0px !important;
  margin-top: 20px !important;
}

.main-page-search-bar .react-datepicker__day-names,
.main-page-search-bar .react-datepicker__week {
  justify-content: flex-start !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
  pointer-events: none !important;
}

.react-datepicker__day-name {
  width: 45px !important;
  height: 45px !important;
  font-family: "Lexend Deca", sans-serif !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  font-size: 14px !important;
  color: #9b938a !important;
  line-height: 14px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-page-search-bar .react-datepicker__day-name {
  width: 45px !important;
  height: 25px !important;
}

.react-datepicker__current-month {
  color: #5a352d !important;
  font-family: "Lexend Deca", sans-serif !important;
  font-weight: 500 !important;
  text-transform: lowercase !important;
  font-size: 18px !important;
  line-height: 16px !important;
}

.main-page-search-bar .react-datepicker__current-month {
  font-size: 16px !important;
}

.react-datepicker__navigation {
  top: 20px;
  color: #5a352d !important;
}

.react-datepicker__day {
  font-weight: normal !important;
  color: #51311f !important;
  font-family: "Lexend Deca", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  width: 45px !important;
  height: 45px !important;
  margin: 1px 0 !important;
  text-align: center;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 0px !important;
}

.main-page-search-bar .react-datepicker__day {
  width: 45px !important;
  height: 35px !important;
  font-size: 10px !important;
}

.react-datepicker__day:hover {
  background-color: #d5cabd !important;
  color: #51311f !important;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 0% !important;
  font-family: "Lexend Deca", sans-serif !important;
  color: #5a352d !important;
  width: 45px !important;
  height: 45px !important;
  margin: 1px 0 !important;
}

.main-page-search-bar .react-datepicker__day.react-datepicker__day--today {
  width: 45px !important;
  height: 35px !important;
}

.past-date,
.excluded-date {
  color: #d5cabd !important;
  opacity: 1 !important;
  pointer-events: none;
}

.excluded-date {
  text-decoration: line-through !important;
  background-color: #fbf7f2 !important;
  color: #d5cabd !important;
}

.excluded-date.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  color: #d5cabd !important;
  background-color: #fbf7f2 !important;
}

.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--range-start,
.react-datepicker__day.react-datepicker__day--range-end {
  background-color: #a75833 !important;
  color: white !important;
  border-radius: 0% !important;
}

.react-datepicker__day.react-datepicker__day--range-start {
  border-top-left-radius: 2.5px !important;
  border-bottom-left-radius: 2.5px !important;
}
.react-datepicker__day.react-datepicker__day--range-end {
  border-top-right-radius: 2.5px !important;
  border-bottom-right-radius: 2.5px !important;
}

.react-datepicker__day.react-datepicker__day--selected:hover,
.react-datepicker__day.react-datepicker__day--range-start:hover,
.react-datepicker__day.react-datepicker__day--range-end:hover {
  background-color: #a75833 !important;
  color: white !important;
}

.react-datepicker__day--in-range {
  border-radius: 0% !important;
  background-color: #f3e6d8 !important;
  color: #5a352d !important;
}

.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #f2d8b3 !important;
  color: #5a352d !important;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #f2d8b3 !important;
  color: #5a352d !important;
}

.react-datepicker__day-name {
  color: #b3886a;
  font-family: "Lexend Deca", sans-serif !important;
  font-weight: 400;
  font-size: 12px;
  text-transform: lowercase;
}

.react-datepicker__navigation {
  color: #5a352d !important;
  top: 15px !important;
}

